import React, {useState} from 'react';
import "../styles/style.scss"
import {Col, Row, Switch, Tabs, Button} from "antd";
import LoueursPartPage from "./tabs/loueurs";
import PrestatairesPartPage from "./tabs/prestataires";
import { useGetTarifsQuery, useUpdateTarifMutation } from './services/tarifs-api';

function TarifsPartPage() {
    const tarifs = useGetTarifsQuery();
    const [concierge, setItemCOncierge] = useState(false);
    const [pmeae, setItemPmeae] = useState(false);
    const [tabSelection, setTabSelection] = useState(1);
    const [updateData, setUpdateData] = useState({});
    const [updateTarifs] = useUpdateTarifMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    const tarifPartnerUpdate = (data) =>{
        updateData["partnerTarif"] = data;
    }

    const tarifCustomerUpdate = (data) =>{
        updateData["customerTarif"] = data;
    }

    const items = [
        {
            key: 1,
            label: <div className="sans-pro-light">Loueurs</div>,
            children: <LoueursPartPage checker={concierge} tarifs={tarifs?.data} onUpdate={tarifCustomerUpdate}/>,
        },
        {
            key: 2,
            label: <div className="sans-pro-light">Prestataires</div>,
            children: <PrestatairesPartPage checker={pmeae} tarifs={tarifs?.data} onUpdate={tarifPartnerUpdate}/>,
        },
    ];
    const onChange = (key) => {
        console.log(key);
        setTabSelection(key);
    };

    const saveDataUpdates = () =>{
        console.log("DATA ======================", updateData);
        updateTarifs(updateData)
        .unwrap()
        .then((res) => {
            setIsLoading(false);
            window.location.reload();
        })
        .catch((error) => {
            setError(error.data.message);
            setIsLoading(false);
        });
    }


    return (
        <div className="tarifs-part-page">
            <h2 className="sans-pro-semi-bold">Tarifs (Hors taxe)</h2>
            {tabSelection === 1 && <div className="change-tarif-moder">
                <div className="leftText">
                    Concierge
                </div>
                <Switch value={concierge} onChange={(checked) => setItemCOncierge(checked)}/>
                <div className="rightText">
                    LMNP
                </div>
            </div>}
            {tabSelection === 2 && <div className="change-tarif-moder">
                <div className="leftText">
                    PME
                </div>
                <Switch value={pmeae} onChange={(checked) => setItemPmeae(checked)}/>
                <div className="rightText">
                    AE
                </div>
            </div>}
            <div className="contentTarifs">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
            </div>
            <div className="buttonValidProfil">
                    <Button className={"annuler"}>Annuler</Button>
                    <Button type={"primary"} onClick={()=>saveDataUpdates()} loading={isLoading} >Enregistrer</Button>
                </div>
                <br/>
        </div>
    );
}

export default TarifsPartPage;
