import React from 'react';
import { DatePicker, Row, Col } from 'antd';
import dayjs from 'dayjs';
import "./styles/style.scss"
import { ResponsiveBar } from '@nivo/bar';
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

function RapportPage() {
    const data = [
        { "date": "2024-01-15", "réalisées": 12, "programmées": 3 },
        { "date": "2024-02-10", "réalisées": 15, "programmées": 5 },
        { "date": "2024-03-20", "réalisées": 20, "programmées": 4 },
        { "date": "2024-04-25", "réalisées": 18, "programmées": 2 },
        { "date": "2024-05-15", "réalisées": 22, "programmées": 1 },
        { "date": "2024-06-30", "réalisées": 25, "programmées": 3 },
        { "date": "2024-07-12", "réalisées": 16, "programmées": 2 },
        { "date": "2024-08-22", "réalisées": 19, "programmées": 4 },
        { "date": "2024-09-05", "réalisées": 14, "programmées": 3 },
        { "date": "2024-10-19", "réalisées": 20, "programmées": 2 },
    ];
    const keys = ['réalisées', 'programmées'];
    const colors = ['#F67504', '#fdefe8'];


    const data2 = [
        { date: "Janvier", chiffreAffaires: 12 },
        { date: "Février", chiffreAffaires: 15 },
        { date: "Mars", chiffreAffaires: 20 },
        { date: "Avril", chiffreAffaires: 18 },
        { date: "Mai", chiffreAffaires: 22 },
        { date: "Juin", chiffreAffaires: 25 },
        { date: "Juillet", chiffreAffaires: 16 },
        { date: "Août", chiffreAffaires: 19 },
        { date: "Septembre", chiffreAffaires: 14 },
        { date: "Octobre", chiffreAffaires: 20 },
        { date: "Novembre", chiffreAffaires: 18 },
        { date: "Décembre", chiffreAffaires: 26 },
    ];


    return (
        <div className='rapport-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Rapports</h1>
                </div>
            </div>

            <div className='page-content'>
                <div className='section-1'>
                    <Row>
                        <Col lg={4}>
                            <RangePicker
                                defaultValue={[dayjs().startOf('month'), dayjs().endOf('month')]}
                                format={dateFormat}
                            />
                        </Col>
                    </Row>
                </div>

                <div className='section-2'>
                    <Row gutter={24}>
                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-note-moyenne.png"` }} />
                                <div className='title'>Note moyenne</div>
                                <div className='subtitle'>Note moyenne obtenue des prestations</div>
                                <div className='note'>4.6</div>
                                <div className='btn-up'>
                                    <div className='up'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-prestation-sans-incidents.png"` }} />
                                <div className='title'>Presta sans incidents</div>
                                <div className='subtitle'>Retard, sans photos, moins de 5 étoiles.</div>
                                <div className='note'>97.6%</div>
                                <div className='btn-up'>
                                    <div className='up'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-clients-actifs.png"` }} />
                                <div className='title'>Clients actifs</div>
                                <div className='subtitle'>Clients avec au moins une prestation programmée</div>
                                <div className='note'>48</div>
                                <div className='btn-down'>
                                    <div className='down'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-ca.png"` }} />
                                <div className='title'>Nouveau CA</div>
                                <div className='subtitle'>Chiffre d’affaires sur nouveaux logements</div>
                                <div className='note'>1 780.48€</div>
                                <div className='btn-down'>
                                    <div className='down'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-panier-moyen.png"` }} />
                                <div className='title'>Panier moyen</div>
                                <div className='subtitle'>Chiffre d’affaires moyen par prestation</div>
                                <div className='note'>124.50€</div>
                                <div className='btn-up'>
                                    <div className='up'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-taux-de-marge.png"` }} />
                                <div className='title'>Taux de  marge</div>
                                <div className='subtitle'>Marge moyenne sur l’ensemble des services</div>
                                <div className='note'>26%</div>
                                <div className='btn-down'>
                                    <div className='down'>+ 6.4%</div></div>
                            </div>
                        </Col>

                        <Col lg={3}>
                            <div className='blc'>
                                <div className='icon' style={{ backgroundImage: `url("assets/img/icon-duree-vie.png"` }} />
                                <div className='title'>Durée de vie</div>
                                <div className='subtitle'>Temps moyen entre 1ère et dernière prestation terminée (Semaines) </div>
                                <div className='note'>14</div>
                                <div className='btn-down'>
                                    <div className='down'>+ 6.4%</div></div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='section-3'>
                    <Row gutter={24}>
                        <Col lg={12}>
                            <div className='title'>
                                Prestations réalisées ou programmées
                            </div>

                            <div className='chart' style={{ height: '450px' }}>
                                <ResponsiveBar
                                    data={data}
                                    keys={keys}
                                    indexBy="date"
                                    margin={{ top: 50, right: 10, bottom: 50, left: 30 }}
                                    padding={0.3}
                                    groupMode="stacked"
                                    colors={colors}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        legend: 'Date',
                                        legendPosition: 'middle',
                                        legendOffset: 40,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Nombre de prestations',
                                        legendPosition: 'middle',
                                        legendOffset: -40,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'bottom-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]}
                                    role="application"
                                    ariaLabel="Stacked bar chart for prestations by date"
                                    barAriaLabel={(e) =>
                                        `${e.id}: ${e.formattedValue} on date: ${e.indexValue}`
                                    }
                                />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <div className='title'>Chiffre d’affaires réalisé (HT)</div>

                            <div className='chart' style={{ height: '450px' }}>
                                <ResponsiveBar
                                    data={data2}
                                    keys={["chiffreAffaires"]} // La clé représentant les valeurs
                                    indexBy="date" // Les catégories en bas du graphique
                                    margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
                                    padding={0.3}
                                    colors={colors}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "Mois",
                                        legendPosition: "middle",
                                        legendOffset: 40,
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: "Chiffre d'affaires (k€)",
                                        legendPosition: "middle",
                                        legendOffset: -40,
                                    }}
                                    labelSkipWidth={12}
                                    labelSkipHeight={12}
                                    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                                    tooltip={({ id, value, indexValue }) => (
                                        <strong>
                                            {indexValue}: {value} k€
                                        </strong>
                                    )}
                                    role="application"
                                    ariaLabel="Bar chart representing monthly revenue"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default RapportPage;
