import React, { useEffect, useState } from 'react';
import { Row, Col, Table, Input, Select, Avatar, Popover, Drawer } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { API_ROOT, convertDateStringToTimeStringComplete } from '../../utils/global-var';
import "moment/locale/fr";
import "./styles/style.scss"
import 'react-circular-progressbar/dist/styles.css';
import ClientsDrawerPage from "./drawer/client-drawer";
import { useClientFetchQuery } from "./services/client-api";
import moment from "moment";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import LogementsDrawerPage from "../logements/drawer/logement-drawer";
function ClientPage() {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const [openLogement, setOpenLogement] = useState(false);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [recorded, setRecord] = useState(null);
    const [recordedLogement, setRecordLogement] = useState(null);
    const allClient = useClientFetchQuery({ page: page, pageSize: pageSize, search: search });
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    useEffect(() => {

    }, [search, page, pageSize]);
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    //const hasSelected = selectedRowKeys.length > 1;

    const contentInfo = (
        <div className="infoClient">
            <p>
                Code couleur : <br />
                Rond vert = Client complété à 100% et <br /> équipe de ménage en place <br />
                Rond orange = Client complété à 100% <br /> sans équipe de ménage <br />
                Rond rouge = Client à compléter (tous <br /> les champs obligatoires ne sont pas remplis
            </p>
        </div>
    );
    const onClose = () => {
        setOpen(false);
    };
    const onCloseLogement = () => {
        setOpenLogement(false);
    };


    const columns = [
        {
            title: "Prénom & Nom",
            width: "20%",
            render: (record) => (
                <div onClick={() => { setRecord(record); setOpen(true) }} className="sans-pro-semi-bold" style={{ cursor: "pointer", color: "#2A3752" }}>
                    <span>{record?.user_lastname} {record?.user_firstname}</span>
                </div>
            ),
        },

        {
            title: 'Email',
            render: (record) => (
                <div style={{ color: "#5B6E8C" }}>
                    <span>{record?.user_email}</span>
                </div>
            ),
        },

        {
            title: 'Téléphone',
            render: (record) => (
                <div style={{ color: "#5B6E8C" }}>
                    <span>{record?.user_phone}</span>
                </div>
            ),
        },
        {
            title: 'Logements actifs',
            render: (record) => (
                <div style={{ color: "#5B6E8C" }}>
                    <span>{record?.logements_actifs}</span>
                </div>
            ),
        },
        {
            title: 'Responsable de site (nb)',
            render: (record) => (
                <div style={{ color: "#5B6E8C" }}>
                    {/*record.responsable.map((item, index) => (
                        <Avatar src={`${API_ROOT}/${record.responsable_avatar}`} key={index} style={{marginLeft:(-((index - index +1) * 6) - 6)+"px"}} />
                    ))*/}
                    {record?.nb_responsables}
                </div>
            ),
        },
        /*{
            title: 'Score de santé',
            width: "7%",
            render: (record) => (
                <div>
                    {record.sante < 5 && <div className='sante-red'>{record.sante}</div>}
                    {record.sante >= 5 && record.sante < 7 && <div className='sante-orange'>{record.sante}</div>}
                    {record.sante >= 7 && <div className='sante-green'>{record.sante}</div>}
                </div>
            ),
        },*/
        /*{
            title: "Segment",
            render: (record) => (
                <div className="segment">
                    {record.segment}
                    <Popover placement="top" content={contentInfo} overlayClassName="infoContentClient">
                        <div className="info-client">
                            <img src="/assets/img/info-circled.png" alt="info" />
                        </div>
                    </Popover>
                </div>
            ),
        },*/
        {
            title: "Crée le",
            render: (record) => (
                <span style={{ color: "#5B6E8C" }}> {convertDateStringToTimeStringComplete(record?.user_createdAt)}</span>
            ),
        },
    ];


    return (
        <div className='client-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Clients</h1>
                </div>
            </div>

            <div>
                <Row>
                    <Col lg={4}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de client..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Santé:</span>
                            <Select
                                defaultValue="a faire"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        key: "faire",
                                        value: 'a faier',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/a-faire-icon.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                A faire (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        key: "cours",
                                        value: 'Tout',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Play-Icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                En cours (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        key: "Terminé",
                                        value: 'Yiminghe',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/done-icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Terminé (0)
                                            </div>
                                        </div>,
                                    },
                                    {
                                        key: "Annulée",
                                        value: 'disabled',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Presta-annulee-icone.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Annulée (0)
                                            </div>
                                        </div>,
                                    },
                                ]}
                                popupClassName="status-classe-select-login"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={
                                    <div className="icon-logement-select">
                                        <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                    </div>
                                }
                                options={[
                                    { key: "Responsable", value: 'Tout', label: 'Tout' },
                                ]}
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Segment:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    { key: "Segment", value: 'Tout', label: 'Tout' },

                                ]}
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Pipeline:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    { key: "Pipeline", value: 'Tout', label: 'Tout' },

                                ]}
                            />
                        </div>
                    </div>

                </div>
                <br />
                <Table
                    className="client-table"
                    // pagination={{ p#b5bba1: 10 }}
                    rowSelection={rowSelection}
                    dataSource={allClient?.data?.users}
                    columns={columns}
                    loading={false}
                    rowKey={(record) => record.id}
                />
                <div className="pagination-custom">
                    <ul>
                        <li className={"fleche " + (page === 1 ? "desactive" : "")} onClick={page === 1 ? null : () => setPage(page - 1)}><IoChevronBackOutline /></li>
                        {page > 3 && <li onClick={() => setPage(1)}>{1}</li>}
                        {page > 4 && <li className="point">...</li>}
                        {page - 2 > 0 && <li onClick={() => setPage(page - 2)}>{page - 2}</li>}
                        {page - 1 > 0 && <li onClick={() => setPage(page - 1)}>{page - 1}</li>}
                        <li className="active" >{page}</li>
                        {page + 1 < allClient?.data?.pages?.totalPages && <li onClick={() => setPage(page + 1)}>{page + 1}</li>}
                        {page + 2 < allClient?.data?.pages?.totalPages && <li onClick={() => setPage(page + 2)}>{page + 2}</li>}
                        {page < allClient?.data?.pages?.totalPages && <li className="point">...</li>}
                        {page < allClient?.data?.pages?.totalPages && <li onClick={() => setPage(allClient?.data?.pages?.totalPages)}>{allClient?.data?.pages?.totalPages}</li>}
                        <li className={"fleche " + (page === allClient?.data?.pages?.totalPages ? "desactive" : "")} onClick={page === allClient?.data?.pages?.totalPages ? null : () => setPage(page + 1)}><IoChevronForwardOutline /></li>
                    </ul>
                </div>
            </div>
            <Drawer title="" onClose={onClose} open={open} width={500} className="client-custom-drawer-class">
                <ClientsDrawerPage record={recorded} closeDrawer={setOpen} setOpenLogement={setOpenLogement} setRecordLogement={setRecordLogement} />
            </Drawer>
            {recordedLogement !== null && <Drawer title="" onClose={onCloseLogement} open={openLogement} width={500}
                className="client-custom-drawer-class">
                <LogementsDrawerPage record={recordedLogement} close={() => {
                    setOpenLogement(false);
                }} />
            </Drawer>}
        </div>
    );
}

export default ClientPage;
